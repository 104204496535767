import "../Courses.css";
import solutions1 from "../../images/solutions1.jpg";
import solutions2 from "../../images/solutions2.jpg";
import solutions3 from "../../images/solutions3.jpg";
import solutions4 from "../../images/solutions4.jpg";
function SolutionsList() {
  return (
    <section className="courses">
      <article className="course">
        <img className="course__image" src={solutions1} alt="solutions1" />
        <div>
          <p className="course__title">
            Solutions Pre-Intermediate Курс рассчитан на подростков уже имеющих
            начальное представление о структуре языка и способных поддержать
            беседу на элементарные темы. В ходе обучения ребята научатся читать
            и обсуждать прочитанный текст, воспринимать английский язык на слух,
            общаться в формальной и неформальной обстановке, формировать и
            выражать свою позицию на различные вопросы. Пользоваться сложной
            грамматикой (условные предложения разных типов, времена перфектной
            группы). По окончании данного курса учащийся может продолжить
            обучение на уровне B1 по международной шкале CEFR.
          </p>
        </div>
      </article>
      <article className="course">
        <img className="course__image" src={solutions2} alt="solutions2" />
        <div>
          <p className="course__title">
            Solutions Intermediate Курс рассчитан на подростков, имеющих
            достаточный для беспрепятственного общения на бытовые и личностные
            темы уровень языка. В процессе изучения курса ребята учатся
            обсуждать более сложные вопросы, строить развернутые высказывания,
            выражать абстрактные идеи и доказывать свою точку зрения. В ходе
            обучения ребята научатся воспринимать сложный текст на слух,
            развернуто высказываться на тему с использованием высокоуровневой
            лексики и продвинутой грамматики. По окончании данного курса
            учащийся может продолжить обучение на уровне B2 по международной
            шкале CEFR.
          </p>
        </div>
      </article>
      <article className="course">
        <img className="course__image" src={solutions3} alt="solutions3" />
        <div>
          <p className="course__title">
            Курс рассчитан на подростков, имеющих средний уровень языка,
            позволяющий им воспринимать большие объемы информации и обсуждать
            актуальные проблемы современного мира и человека в нем. Подросток
            учится вести развернутые дискуссии, четко и последовательно излагать
            свои мысли с использованием лексических и грамматических средств
            языка, анализировать чужую речь и распознавать дополнительные
            смысловые оттенки и намеки в ней, а также свободно владеть самыми
            распространенными видами письменной деятельности, будь то ведение
            переписки с другом или подготовка эссе на конкурс. Он уверенно
            употребляет сложные времена группы Perfect, пользуется средствами
            усиления эмоциональной окраски речи, сравнивает картинки, проводит
            интервью, дискуссии и презентации. Курс состоит из двух частей и
            рассчитан на два года обучения. По окончании данного курса учащийся
            может продолжить обучение на уровне С1 по международной шкале CEFR.
          </p>
        </div>
      </article>
      <article className="course">
        <img className="course__image" src={solutions4} alt="solutions4" />
        <div>
          <p className="course__title">
            Курс рассчитан на подростков, уже владеющих языком на уровне «выше
            среднего» и готовых продвинуться дальше. Подростки разрешают
            конфликты, размышляют над дилеммами и приходят к логически
            выверенным выводам. На данном курсе студент закладывает основу
            профессионального владения английским языком, пополняет словарный
            запас живой лексикой повышенной сложности, учится воспринимать на
            слух речь, осложненную акцентом, скоростью и т.д. На занятиях
            студенты пишут эссе, рецензии, статьи соответствующие требованиям и
            правилам сдачи государственных и международных экзаменов, а также
            уверенно оперирует сложнейшей лексикой и с уверенностью излагает
            информацию как в устном, так и письменном формате в виде
            художественных отрывков, писем, отчетов. Курс состоит из двух частей
            и рассчитан на два года обучения.
          </p>
        </div>
      </article>
    </section>
  );
}
export default SolutionsList;
