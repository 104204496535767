import "./Online.css";
import online from "../images/online.jpg";
function Online() {
  return (
    <article className="online">
      <img src={online} alt="onlineCourses" className="online__image" />
      <p className="online__description">
        {" "}
        Занимайтесь из дома или офиса, если Вам неудобно посещать очные занятия!
      </p>
      <p className="online__description">
        Мы стремимся обеспечить своим ученика комфортную среду обучения и следим
        за новыми тенденциями в сфере образования, поэтому предлагаем Вам
        обучение в дистанционном формате.
      </p>
      <p className="online__description">
        В нашем центре Вы можете подобрать группу online обучения или группу
        смешанного формата: это значит, что вместо двух очных занятий в неделю,
        одно Вы будете посещать дистанционно и/или посещать очные занятия раз в
        месяц для проведения тестов.{" "}
      </p>
      <p className="online__description">
        Все условия будут согласованы с Вами, участниками Вашей группы и
        прописаны в Вашем договоре. Также мы всегда переводим наши уроки в
        дистанционный формат во время пиков заболеваемости, карантинов и
        некомфортных погодных условий.
      </p>
      <a
        className="footer__linkItem link"
        href="https://t.me/StratfordSchool_bot"
        target="_blank"
        rel="noopener noreferrer"
      >
        Запишитесь
      </a>{" "}
      на пробное занятие онлайн и оцените какой формат Вам подходит больше.
    </article>
  );
}
export default Online;
