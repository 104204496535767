import "../Online.css";
import adultProgramsSpecialized from "../../images/specialized courses.jpg";
function Specialized() {
  return (
    <article className="online">
      <h4 className="online__title">
        {" "}
        Английский для специалистов
      </h4>
      <img
        src={adultProgramsSpecialized}
        alt="levelsCourses"
        className="online__image"
      />
      <p className="online__description">
      Помимо профессионального владения методиками обучения английскому языку по общим
программам, наши преподаватели специализируется на узкопрофильных курсах и в течение
многих лет успешно преподают английский язык для профильных направлений.
Владение языком – это отличный инструмент для саморазвития, а владение английским языком в
профильном узкопрофессиональном направлении – это еще и отличная возможность для
трудоустройства и карьерного роста.
Программа "Английский для специалистов" разработана для бизнесменов, программистов,
юристов, экономистов, инженеров, бортпроводников, врачей, специалистов в сфере ресторанного
бизнеса, бьюти-индустрии и других сфер, отраслей, специальностей и профессий, которые
требуют знания английского языка.
Все наши специализированные курсы ведут преподаватели – методисты, которые
индивидуально составляют для Вас эффективную программу, подходящую под ваши цели
и сроки. По окончании курса Вы получаете сертификат установленного образца (ссылка на
лицензию) а также приложение, в котором будут указаны пройденные вами темы и освоенные
навыки.
      </p>
      <p className="online__description">
        <a
          className="footer__linkItem link"
          href="https://t.me/StratfordSchool_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Запишитесь{"  "}
        </a>
        на бесплатный пробный урок сейчас и узнайте какой
курс подойдет Вам!

      </p>
    </article>
  );
}
export default Specialized;
