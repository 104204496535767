import "../Online.css";
import adultProgramsSpeaking from "../../images/speaking course.jpg";
function ChildrenSpeaking() {
  return (
    <article className="online">
      <h4 className="online__title">Программа "разговорный английский для школьников и подростков" рассчитана на ребят c
      разным уровнем владения языком: от A1 (начальный) до B1 (средний).</h4>
      <img
        src={adultProgramsSpeaking}
        alt="levelsCourses"
        className="online__image"
      />
      <p className="online__description">
      Данная программа основывается на известном УМК издательства Oxford University Press и
дополнена разработками наших преподавателей и штатного детского психолога.
На уроках мы с ребятами учимся не только разговаривать на английском языке, но и мыслить
критически, оттачивая навыки общения на актуальных для ребят каждой возрастной группы
темах: здесь мы поговорим о пользе и вреде соцсетей, о разнице восприятия мира детьми и
взрослыми, о свободном времени, влогах, нейросетях, финансовой грамотности, пользе
здорового сна, а также психологии, буллинге, о том, как важно говорить о своих чувствах и многом
другом. И все это исключительно на английском языке :)
Да! Здесь, мы говорим на американском английском. Но поверьте, это лишь расширит
возможности интересного общения в любой англоязычной среде.

      </p>
      <p className="online__description">
        <a
          className="footer__linkItem link"
          href="https://t.me/StratfordSchool_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Запишитесь{"  "}
        </a>
        на пробный урок сегодня и узнайте о больше о курсе и условиях записи на
        него.
      </p>
    </article>
  );
}
export default ChildrenSpeaking;
