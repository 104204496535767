import primarySchool from "../images/primarySchool.jpg";
import middleSchool from "../images/middleSchool.jpg";
import highSchool from "../images/high school.jpg";
import adultProgramsImage from "../images/adults courses.jpg";
import adultProgramsLevels from "../images/studying by levels.jpg";
import adultProgramsSpecialized from "../images/specialized courses.jpg";
import adultProgramsSpeaking from "../images/speaking course.jpg";
import childrenProgramsImage from "../images/kids courses.jpg";
import ogeImage from "../images/oge.jpg";
import ieltsImage from "../images/TOEFL IELTS.jpg";
import examsProgramsImage from "../images/exams.jpg";

export const mainPrograms = [
  {
    id: 1,
    name: "Программы для взрослых",
    description:
      "Данная программа предполагает всестороннее изучение английского языка",
    src: adultProgramsImage,
    link: "adults",
  },
  {
    id: 2,
    name: "Программы для детей",
    description: "Универсальные программы, которые без сомнений понравятся Вашему ребенку и порадуют Вас его успехами",
    src: childrenProgramsImage,
    link: "children",
  },
  {
    id: 3,
    name: "Подготовка к экзаменам",
    description: "Подготовка к любому экзамену: от государственных до международных",
    src: examsProgramsImage,
    link: "exams",
  },
];
export const childrenPrograms = [
  {
    id: 4,
    name: "Программа «Primary School»",
    description:
      '"Primary School" - программы для начальных классов: с 1 по 4 классы.',
    src: primarySchool,
    link: "primary",
  },
  {
    id: 5,
    name: "Программа «Middle School»",
    description:
      '"Middle School" - программы для средних классов: с 5 по 8 классы.',
    src: middleSchool,
    link: "middle",
  },
  {
    id: 6,
    name: "Программа «High school»",
    description:
      '"High School" - программы для старших классов: с 9 по 11 классы.',
    src: highSchool,
    link: "high",
  },
  {
    id: 7,
    name: "Разговорный для детей",
    description:
      'Программа "разговорный английский для школьников и подростков" рассчитана на ребят c разным уровнем владения языком',
    src: adultProgramsSpeaking,
    link: "childrenSpeaking",
  },
];

export const adultPrograms = [
  {
    id: 8,
    name: "Изучение языка по уровням",
    description:
      "Изучение языка по уровням - самый базовый и проверенный способ всестороннего освоения языка",
    src: adultProgramsLevels,
    link: "levels",
  },
  {
    id: 9,
    name: "Английский для специалистов",
    description:
      "Узкопрофильные курсы английского языка для профильных направлений",
    src: adultProgramsSpecialized,
    link: "specialized",
  },
  {
    id: 10,
    name: "Разговорный английский",
    description:
      "Цель данного курса – дать студентам возможность эффективно общаться, используя английский язык в англоязычной среде",
    src: adultProgramsSpeaking,
    link: "speaking",
  },
];

export const examsPrograms = [
  {
    id: 11,
    name: "ОГЭ/ЕГЭ",
    description:
      "Курс подготовки к ОГЭ/ЕГЭ",
    src: ogeImage,
    link: "oge",
  },
  {
    id: 12,
    name: "IELTS/TOEFL",
    description:
      "Курс подготовки к международным экзаменам форматов IELTS/TOEFL",
    src: ieltsImage,
    link: "ielts",
  },
  {
    id: 13,
    name: "CAMRIDGE",
    description:
      "Курс подготовки к международному тестированию CAMRIDGE ENGLISH LANGUAGE ASSESSEMENT",
    src: examsProgramsImage,
    link: "cambridge",
  },
];

export const reviewsUrl = 'https://2gis.ru/chelyabinsk/search/Stratford%20School%2C%20%D1%8F%D0%B7%D1%8B%D0%BA%D0%BE%D0%B2%D0%BE%D0%B9%20%D1%86%D0%B5%D0%BD%D1%82%D1%80/firm/2111590608832897/61.386754%2C55.162236/tab/reviews?m=61.386901%2C55.161901%2F19.13';
