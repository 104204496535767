import "./Exams.css";
import ieltsImage from "../../images/TOEFL IELTS.jpg";
function IELTS() {
  return (
    <section className="exams">
      <h3>Успешная сдача IELTS/TOEFL с языковым центром StratfordSchool!</h3>
      <img src={ieltsImage} alt="ieltsImage" className="exams__image" />

      <p>Об экзамене:</p>
      <p className="exams__text">
        С весны 2022 года центры IELTS, TOEFL и Cambridge Assessment English
        временно не работают на территории Российской Федерации. Однако, это не
        повод опускать руки и не идти к своей цели, если она у Вас была! Вы все
        еще можете сдать международный экзамен за рубежом. Самые популярные
        страны для тестирования сейчас: Казахстан, Азербайджан, Грузия, Армения,
        Узбекистан и Турция. А если Вам требуется наставник или Вы хотите
        записаться на курс по подготовке к международным экзаменам, мы будем
        рады вам помочь. Наши преподаватели имеют богатый опыт работы в сфере
        профессиональной подготовки к экзаменам форматов IELTS и TOEFL и готовы
        стать проводником в достижении этой цели.<br></br>
        <span className="exams__span">Важно:</span> Подготовка к международным
        экзаменам IELTS и TOEFL осуществляется при достижении уровне не ниже{" "}
        <span className="exams__span">Intermediate!</span>
      </p>
      <p>Подготовка к IELTS</p>
      <p className="exams__text">
        При записи на курс{" "}
        <span className="exams__span">подготовка к IELTS</span>, Вы проходите{" "}
        <span className="exams__span">бесплатное тестирование</span> для того,
        чтобы определить Ваш уровень владения языком. После того, как Вам будет
        известен результат, можно оценить Ваши возможности и подобрать
        подходящую программу.
      </p>
      <p className="exams__text">
        При уровне <span className="exams__span">Intermediate+</span> Вы
        приступаете к непосредственной подготовке к экзамену IELTS по
        соответствующим актуальным тестовым материалам. Занятия с опытным
        преподавателем (индивидуально или в группе) дадут Вам возможность
        ознакомиться со стратегией сдачи экзамена, его требованиями и закрепить
        весь необходимый материал на практике. На наших уроках преподаватель
        знакомит учеников с техниками выполнения всех потенциальных типов
        заданий в частях Reading, Listening, Writing и Speaking на практике и
        отрабатывает с каждым учеником ту технику, которая подходит именно ему.
        Если Ваш уровень ниже{" "}
        <span className="exams__span">Pre-Intermediate,</span> Вам нужно
        запастись необходимым набором знаний и навыков, которые позволят Вам
        легко влиться в подготовку к сдаче экзамена IELTS. Для того, чтобы
        максимально сократить время подготовки к уровню Intermediate, наши
        преподаватели создали для своих учеников специальные программы,
        основанные на материалах лучших методических пособий изданий Oxford и
        Cambridge.
      </p>
      <p>Подготовка к TOEFL</p>
      <p className="exams__text">
        При записи на курс{" "}
        <span className="exams__span">подготовка к TOEFL</span>, Вы проходите{" "}
        <span className="exams__span">бесплатное тестирование</span> для того,
        чтобы определить свой уровень владения языком. После того, как результат
        будет известен, можно оценить Ваши возможности и подобрать подходящую
        программу. При уровне{" "}
        <span className="exams__span">Intermediate +</span> Вы приступаете к
        непосредственной подготовке к экзамену TOEFL по материалам и тестам,
        соответствующим структуре самого экзамена. Если Ваш уровень ниже
        Pre-Intermediate, Вам нужно запастись необходимым набором знаний и
        навыков, которые позволят Вам легко влиться в подготовку к сдаче
        экзамена. Мы предлагаем Вам начать свою подготовку с методических
        пособий, которые уже на первых этапах обучения ориентированы на
        подготовку к экзамену. Это существенно облегчит Ваши усилия и позволит
        подготовиться к тесту за максимально короткие сроки. Всю информацию о
        рекомендованной литературе Вам расскажут наши преподаватели после сдачи
        предварительного теста. Для успешного прохождения теста TOEFL на
        максимально высокий балл, Вам придется приложить немало усилий в
        процессе подготовки. Каждый кандидат должен знать структуру теста, чтобы
        проработать тактику подготовки и добиться последовательности действий на
        экзамене. В процессе подготовки наши преподаватели указывают на слабые
        места учеников и дают рекомендации по их устранению. Проверки эссэ,
        оттачивание разговорных навыков и подготовка к другим разделам теста
        сопровождаются ценными комментариями и полезными советами. Регулярно
        проводимые тесты помогут Вам самим оценить прогресс своего обучения и
        понять, когда будет разумно регистрироваться на экзамен.
      </p>
      <p className="exams__text">
        <a
          className="footer__linkItem link"
          href="https://t.me/StratfordSchool_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Запишитесь
        </a>{" "}
        на пробный урок, чтобы проверить уровень владения языком, получить
        рекомендации нашего преподавателя и узнать все подробности о процессе
        подготовки, материалах, а также условиях записи на курс.
      </p>
    </section>
  );
}

export default IELTS;
