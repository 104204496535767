import React from "react";
import { Carousel } from "react-responsive-carousel";
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.css";
import "./Carousel.css";
import ogeImage from "../../images/oge.jpg";
import childrenSpeaking from "../../images/kids courses.jpg";
import adultsSpeaking from "../../images/specialized courses.jpg";
import courses from "../../images/primarySchool.jpg";
import courses1 from "../../images/studying by levels.jpg";
import { Link } from "react-router-dom";

const MyCarousel = () => (
  <Carousel autoPlay={true} showThumbs={false} infiniteLoop={false} showStatus={false}>
    <div>
    <Link to="/oge">
      <img alt="Oge" src={ogeImage} style={{borderRadius: '25px'}}/>
      <p className="legend_important">Подготовка к ОГЭ/ЕГЭ</p>
    </Link></div>
    <div><Link to="/childrenSpeaking">
      <img alt="" src={childrenSpeaking} style={{borderRadius: '25px'}}/>
      <p className="legend_important">Разговорный для школьников</p></Link>
    </div>
    <div><Link to="/speaking">
      <img alt="" src={adultsSpeaking} style={{borderRadius: '25px'}}/>
      <p className="legend_important">Разговорный для взрослых</p></Link>
    </div>
    <div><Link to="/children">
      <img alt="" src={courses} style={{borderRadius: '25px'}}/>
      <p className="legend_important">Курсы для детей</p></Link>
    </div>
    <div><Link to="/adults">
      <img alt="" src={courses1} style={{borderRadius: '25px'}}/>
      <p className="legend_important">Курсы для взрослых</p></Link>
    </div>
  </Carousel>
);

export default MyCarousel;
