import "./Exams.css";
import ogeImage from "../../images/oge.jpg";
function OGE() {
  return (
    <section className="exams">
      <h3>Успешная сдача ОГЭ/ЕГЭ с языковым центром StratfordSchool!</h3>
      <img src={ogeImage} alt="OGE" className="exams__image" />

      <p>Об экзамене:</p>
      <p className="exams__text">
        Экзамен - всегда стресс и тревога для каждого из нас. Когда это первые
        экзамены в жизни: школьные выпускные государственные, уровень тревоги
        особенно высок. Помочь своему ребёнку снизить стрессовую нагрузку можно
        уже сегодня, начав готовится к экзаменам заранее. Имея большой опыт
        работы в подготовке учеников к экзаменационным мероприятиям, мы
        разработали свой алгоритм работы с ребятами с разным уровнем владения
        языком.
      </p>
      <p>На наших уроках мы:</p>
      <ul className="exams__list">
        <li>
          прорабатываем все аспекты, необходимые для успешной сдачи ОГЭ и ЕГЭ:
          теорию, подготовку к "говорению" и аудированию, алгоритм работы с
          текстами и написаниями писем.
        </li>
        <li>
          работаем с актуальными контрольно-измерительные материалы, каждый год
          дополняя и улучшая свои методические разработки для того, чтобы
          ребятам было легче воспринимать материал, и они могли получать только
          проверенную и актуальную информацию.
        </li>
        <li>
          проводим пробные тесты несколько раз в течение учебного года для того,
          чтобы отследить динамику и уровень подготовки ребят.
        </li>
        <li>
          А также всегда поддерживаем связь с родителями и проговариваем все
          сложности, с которыми мы можем столкнуться во время подготовки к
          экзамену, ненавязчиво призывая оказывать своему ребенку
          психологическую поддержку, проявлять веру в него, соучастие и
          уверенность в его силах.
        </li>
      </ul>

      <p className="exams__text">
        Опыт работы с подростками разных индивидуальных особенностей доказал нам
        важный психологический тезис: чем больше ребенок боится неудачи, тем
        более вероятность допущения ошибок. Повышайте уверенность в себе в своих
        детях, а мы сделаем все, чтобы приобретенные на наших уроках знания
        помогли им получить максимальный балл на ОГЭ и ЕГЭ.
      </p>
      <p className="exams__text">
        <a
          className="footer__linkItem link"
          href="https://t.me/StratfordSchool_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Запишитесь
        </a>{" "}
        на пробный урок, чтобы проверить уровень владения языком, получить
        рекомендации нашего преподавателя и узнать все подробности о процессе
        подготовки, материалах, а также условиях записи на курс.
      </p>
    </section>
  );
}

export default OGE;
