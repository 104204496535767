import MyCarousel from "../Carousel/Carousel";
import "./AboutUs.css";
function AboutUs() {
  return (
    <section className="aboutUs">
      <MyCarousel/>
      <p className="aboutUs__description">
        Языковой центр{" "}
        <span className="aboutUs__accent">Startford School </span>- центр,
        которому доверяют уже более 12 лет. С 2012 годы мы развиваемся и
        становимся профессиональнее для Вас и вместе с Вами.{" "}
        <p>
          <span className="aboutUs__accent">Наша цель</span> - давать
          качественные знания.{" "}
        </p>
        <p>
          <span className="aboutUs__accent">Наши методики</span> основаны на
          исключительно индивидуальном подходе к каждому студенту, независимо от
          формы обучения. Поэтому мы НЕ набираем больших групп и преподаем в
          форматах мини-групп, пар или индивидуально.
        </p>{" "}
        <span className="aboutUs__accent">Наши преподаватели</span> - высококлассные специалисты с богатым опытом
        преподавательской деятельности и с подтвержденными знаниями иностранных
        языков.{" "}
        <p>
          <span className="aboutUs__accent">Наши программы</span> разработаны на основе
          лучших зарубежных учебных пособий таких изданий как: Oxford,
          Cambridge, Longman, Macmillan, Express Publishing и дополнены
          индивидуальными разработками наших методистов для того, чтобы каждый
          урок проходил еще более интересно и увлекательно. Все эти 12 лет мы НИ
          РАЗУ не пользовались услугами рекламы. На нас работают отзывы наших
          учеников, "сарафанное радио" и наша репутация. знаниями иностранных
          языков.{" "}
        </p>
        <span className="aboutUs__accent">Наш успех - Ваши знания.</span>
      </p>
    </section>
  );
}

export default AboutUs;
