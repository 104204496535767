import "../Online.css";
import adultProgramsLevels from "../../images/studying by levels.jpg";
function Levels() {
  return (
    <article className="online">
      <h4 className="online__title">
        {" "}
        Изучение языка по уровням - самый базовый и проверенный способ
        всестороннего освоения языка. На наших уроках мы прорабатываем все
        необходимые аспекты, соответствующие требованиям Европейских и
        международных стандартов владения тем или иным уровнем английского
        языка.
      </h4>
      <img
        src={adultProgramsLevels}
        alt="levelsCourses"
        className="online__image"
      />
      <p className="online__description">
        Данная программа состоит из шести ступеней: Beginner; Elementary;
        Pre-Intermediate; Intermediate; Upper-Intermediate; Advanced и основана
        на лучших методических пособиях по изучению английского языка таких
        изданий как: Oxford, Cambridge, Longman, Macmillan, Express Publishing и
        дополнены индивидуальными разработками наших методистов для того, чтобы
        каждый урок проходил еще более интересно и увлекательно. Каждый уровень
        предполагает свои знания, умения, нюансы и занимает от 100 до 200
        академических (от 4 до 8 месяцев). Каждый пройденный вами курс является
        самостоятельным и не требует от вас перехода на следующую ступень, если
        вы не считаете это нужным. По окончании каждой ступени вы получаете
        сертификат установленного образца (ссылка на лицензию). В нашем языковом
        центре Вы можете записаться на бесплатный пробный урок для определения
        своего уровня, а затем начать обучение с самых основ или продолжить
        обучение языка с того уровня, на котором вы остановились ранее
      </p>
      <p className="online__description">
        <a
          className="footer__linkItem link"
          href="https://t.me/StratfordSchool_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Запишитесь{"  "}
        </a>
        сейчас и мы с радостью поделимся с Вами нашим опытом, расскажем об
        используемых на уроках материалах, а также дадим полезные рекомендации,
        если вы решили осваивать язык самостоятельно!
      </p>
    </article>
  );
}
export default Levels;
