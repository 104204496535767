import "../Courses.css";
import flyHigh1 from "../../images/flyhigh1.png";
import flyHigh2 from "../../images/flyHigh2.jpg";
import flyHigh3 from "../../images/flyHigh3.jpg";
import flyHigh4 from "../../images/flyHigh4.jpg";
function FlyHighList() {
  return (
    <section className="courses">
      <p className="course__description">
        Программа «Primary School» рассчитана на пошаговое развитие у ребёнка
        четырёх основных аспектов изучения иностранного языка: письмо, чтение,
        общение и понимание иностранной речи. Веселый и мотивирующий курс
        развивает не только лингвистические навыки ребёнка, но и охватывает
        необходимые общие знания, которые позволяют ребятам легко
        ориентироваться в различных ситуациях. Данная программа основана на
        материалах одного из лучших методических пособий по изучению английского
        языка для детей от 5-11 лет от издательства Cambridge “Fly High”и
        дополнена индивидуальными разработками наших преподавателей. Мы
        стремимся делать праздник на каждом уроке, не перегружая детей, но и не
        позволяя им упустить и не усвоить необходимый материал. В этом нам
        помогают интерактивная и игровая формы обучения. В ходе обучения ребенок
        знакомится с типовыми заданиям государственных (ОГЭ и ЕГЭ) и
        международных экзаменов, что готовит его к успешной сдаче тестов в
        старших классах. Готовьтесь к успеху заранее!
      </p>
      <article className="course">
        <img className="course__image" src={flyHigh1} alt="flyhigh1" />
        <div>
          <p className="course__title">
            Fly High 1 - курс помогает приобрести базовые навыки письма, чтения,
            а также получить необходимый для базового уровня словарный запас.
            Рекомендован детям 6-7 лет.
          </p>
          <ul className="course__list">
            После окончания курса учащиеся:
            <li>
              Научатся читать и считать на английском. Овладеют наиболее
              употребляемой лексикой в рамках тематик текущего базового уровня.
            </li>
            <li>
              Освоят основы правильного произношения звуков и отдельных слов.
              Научатся строить небольшие диалоги.
            </li>
            <li>
              Научатся понимать основное содержание коротких, несложных текстов
              и выделять значимую информацию.
            </li>
            <li>
              Смогут выполнять тестовые работы без стресса и с удовольствием.
              Все работы ребят оцениваются в баллах, а значит нет плохих оценок
              которые могут травмировать ребенка, но есть своевременный
              мониторинг текущей успеваемость и заинтересованности ребят.
            </li>
          </ul>
        </div>
      </article>
      <article className="course">
        <img className="course__image" src={flyHigh2} alt="flyhigh2" />
        <div>
          <p className="course__title">
            Fly High 2 - курс рассчитан на совершенствование навыков письма и
            чтения, а также развитие уверенных языковых компетенций.
            Рекомендован для детей 7-8 лет.
          </p>
          <ul className="course__list">
            После окончания курса учащиеся смогут:
            <li>
              Говорить простыми и сложными предложениями. Использовать
              вопросительные и отрицательные формы предложения.
            </li>
            <li>
              Строить небольшие диалоги и рассказы на темы: в школе, животные,
              семья, друзья, хобби, дом, праздники.
            </li>
            <li>Читать и считать на английском. Пополнит словарный запас.</li>
          </ul>
        </div>
      </article>
      <article className="course">
        <img className="course__image" src={flyHigh3} alt="flyhigh3" />
        <div>
          <p className="course__title">
            Fly High 3 - курс предназначен для более глубокого изучения
            английского языка детьми 9-10 лет, с хорошо отработанными навыками
            чтения и письма.
          </p>
          <ul className="course__list">
            После окончания курса учащийся:
            <li>
              Познакомится с простыми временами, научится их различать и
              использовать в живой речи.
            </li>
            <li>
              Научится выражать свои мысли, вести диалоги, воспринимать речь на
              слух. Читать и определять основную мысль текст.
            </li>
            <li>
              Познакомиться со структурой письма и других письменных работ,
              необходимых для повседневного письменного общения, а также для
              постепенной подготовки к государственным и международным
              экзаменам.
            </li>
            <li>
              Сможет самостоятельно выполнять домашние работы в школе, подтянет
              свой уровень английского, и улучшит успеваемость.
            </li>
          </ul>
        </div>
      </article>
      <article className="course">
        <img className="course__image" src={flyHigh4} alt="flyhigh4" />
        <div>
          <p className="course__title">
            Fly High 4 - завершающий курс по программе Fly High, предназначенный
            для детей прошедших курс Fly High 1,2,3 и имеющих структурированную
            базу и солидный словарный запас.
          </p>
          <ul className="course__list">
            После окончания курса учащиеся смогут:
            <li>
              Наиболее употребляемой лексике в рамках тематики данного этапа.
            </li>
            <li>
              Использовать в речи сложные времена для выражения сложных идей.
            </li>
            <li>
              Овладеет навыками письменной речи, отработает структуру написания
              писем и сочинений по заданной тематике.
            </li>
            <li>
              Читать и анализировать не только маленькие тексты, но полноценные
              рассказы.
            </li>
          </ul>
        </div>
      </article>
    </section>
  );
}
export default FlyHighList;
