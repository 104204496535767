import "./Faq.css";
import { NavLink } from 'react-router-dom';
function Faq() {
  return (
    <section className="faq">
      <details className="faq__item">
        <summary className="faq__question">Когда я могу начать обучение?</summary>
        <p className="faq__answer">
          Мы запускаем новые группы три раза в год: в сентябре, в декабре и в
          июне. На индивидуальные и парные занятия можно записываться в течение
          всего учебного года.
        </p>
      </details>

      <details className="faq__item">
        <summary className="faq__question">Сколько человек будет в моей группе?</summary>
        <p className="faq__answer">
          В нашем центре вы можете выбрать три формы обучения: индивидуальные
          занятия, занятия в паре и в мини-группе. В мини-группе обучаются от 3х
          до 6 студентов.
        </p>
      </details >
      <details className="faq__item">
        <summary className="faq__question">По каким методическим комплексам вы преподаете?</summary>
        <p className="faq__answer">
          Уроки по нашим основные курсам проходят на базе методических пособий
          издательств Cambridge и Охford. Однако, занятия не ограничиваются
          применением только курсовых учебников. Наши преподаватели сами
          разрабатывают материалы к текущим темам, чтобы ученикам было еще
          интереснее заниматься.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">Что делать, если я не могу прийти на урок?</summary>
        <p className="faq__answer">
          Если вы не можете присутствовать на занятии и заранее предупредили об
          этом администратора, мы обязательно восстановим пропущенный вами
          материал в индивидуальном порядке или с другой группой, в удобное для
          вас и учителя время, в очном или дистанционном формате. Также, мы с
          радостью согласуем с вами любой необходимый перенос. Главное, сообщить
          об этом заранее.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">Вы работаете в карантин?</summary>
        <p className="faq__answer">
          На случай возникновения эпидемиологической обстановки мы переводим
          наши уроки в онлайн формат. Также, если есть симптомы простуды, мы
          всегда поддержим ваше желание заниматься дома в онлайн формате чтобы
          обезопасить себя и других.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">Вы работаете в каникулы?</summary>
        <p className="faq__answer">
          Мы учимся во время осенних и весенних каникул. Не работаем во время
          зимних каникул. А в летние каникулы мы работаем по летним программам,
          с которыми вы можете ознакомиться <span className="faq__link"><NavLink to="/programs">здесь</NavLink></span>.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">Мне нужно будет делать домашнее задание?</summary>
        <p className="faq__answer">
          Обучение всегда предполагает закрепление дома пройденного материала и
          разница в прогрессе обучения между теми учениками, кто готовит
          домашнее задание дома, а кто нет — всегда очевидна. Однако, мы
          стараемся подбирать объём домашних заданий в соответствии с
          индивидуальными пожеланиями, целями и возможностями студента и группы.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">Я буду учиться онлайн или очно?</summary>
        <p className="faq__answer">
          В нашем языковом центре вы можете обучаться в очном и дистанционном
          форматах. Также, вы можете выбрать группу смешанного формата: это
          значит, что ваша группа будет заниматься один день в неделю в классе,
          а в другой день в дистанционно.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">
          Что делать если занятие прервалось из-за проблем с интернетом,
          электричеством?
        </summary>
        <p className="faq__answer">
          Не беспокойтесь, в таком случае преподаватель назначит другое время и
          вы полностью отработаете занятие.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">
          Мне нужен специализированный курс, можете ли Вы составить мне
          индивидуальную программу?
        </summary>
        <p className="faq__answer">
          Да, это возможно. Специализированный курс ведут преподаватели –
          методисты, которые индивидуально составляют для Вас эффективную
          программу, подходящую под ваши цели и сроки.
        </p>
      </details>
      <details className="faq__item">
        <summary className="faq__question">
          Сколько нужно времени чтобы заговорить на иностранном языке?
        </summary>
        <p className="faq__answer">
          Пожалуй, это самый частый вопрос, который нам задают ученики на
          пробном уроке. Мы учимся говорить на родном языке с детства, но хотим
          освоить иностранный за три месяца. Призываем вас не идти на поводу у
          тех, кто обещает вам освоить язык за столь короткий срок. Заучить
          распространенные среди туристов фразы и свободно владеть языком — ни
          одно и тоже. Уровень английского языка ранжируется от A1 (beginner) до
          C2 (advanced). На освоение каждого уровня требуется время, например,
          для уровней A1 – A2 необходимо 60 – 200 часов, 6 – 18 месяцев и чем
          чаще практика — тем быстрее результат. Изучение языка процесс не
          быстрый, но удивительно интересный и мы с радостью поможем вам пройти
          этот увлекательный путь.
        </p>
      </details>
    </section>
  );
}
export default Faq;
